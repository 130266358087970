import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import RichText from '../components/richText';


const PreguntasFrecuentes = (props) => {


  const showCrediton = true;
  const assets = 'assets'



  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Metodos
  const $preguntasList = props.data.prismic.allPreguntas_frecuentess.edges[0].node

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);


  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-preguntas'} >


        <SEO metadata={$metadata} title={'Preguntas frecuentes'}></SEO>

        <>


          <Helmet>

            <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=10`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=4`)} rel="stylesheet" type="text/css" />




          </Helmet>


          <div className="o-wrapper o-section o-section--large">
            <h2 className={`c-title c-title--center c-title--blue c-faqs__title ${showCrediton && 'c-title--red'}`}>

              Preguntas frecuentes

            <br />
              {!showCrediton && <span className="c-faqs__bajada">¡Te sacamos <strong class={`c-title--outline`}>todas tus dudas!</strong></span>}

              {showCrediton && <span className="c-viewport__subtitle c-text c-title--blue c-faqs__subtitle c-title--green">¡Te sacamos todas tus dudas!</span>}


            </h2>



            {hasMounted &&

              <Helmet>

                <script src={`/assets/js/sitio/faqs.js`}></script>

              </Helmet>

            }



            <ul className="c-faqs">

              {$preguntasList.item.map((field, i) => (

                <li key={i} className={`c-faqs__item `} >
                  <h3 className="c-faqs__question"><span className="c-faqs__question-aligner">{field.pregunta[0].text}</span></h3>
                  <div className="c-faqs__answer c-text">

                    <RichText render={field.respuesta} />

                  </div>
                </li>

              ))}


            </ul>
          </div>


        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query PreguntasQuery {
          prismic {
        allMetadatas  {
          edges {
        node {
          ...MetadataFragment
        }
        }
      },
    allFooters {
          edges {
        node {
          ...FooterFragment
        }
        }
      },
      allPreguntas_frecuentess {
        edges {
          node {
            item {
              pregunta
              respuesta
            }
          }
        }
      },
      allInformacion_financieras {
        edges {
          node {
            ...InformacionFinancieraFragment
          }
        }
      }
}
}
`


export default PreguntasFrecuentes;

